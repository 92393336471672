<template>
  <div class="container">
    <pcPublicHeader />
    <div class="public-content">
      <div class="page-title">我的账户</div>
      <div class="tab-item">
        <div class="item" :class="Type == 0 ? 'active' : ''" @click="handleChangeType(0)">全部</div>
        <div class="item" :class="Type == 1 ? 'active' : ''" @click="handleChangeType(1)">充值</div>
        <div class="item" :class="Type == 2 ? 'active' : ''" @click="handleChangeType(2)">购买商品</div>
        <div class="item" :class="Type == 3 ? 'active' : ''" @click="handleChangeType(3)">接口扣款</div>
        <!-- <div class="item" :class="Type == 4 ? 'active' : ''" @click="handleChangeType(4)">余额转佣金</div>
        <div class="item" :class="Type == 5 ? 'active' : ''" @click="handleChangeType(5)">佣金转余额</div> -->
        <div class="item" :class="Type == 6 ? 'active' : ''" @click="handleChangeType(6)">系统</div>
        <div class="item" :class="Type == 7 ? 'active' : ''" @click="handleChangeType(7)">退款</div>
        <div class="item" :class="Type == 8 ? 'active' : ''" @click="handleChangeType(8)">站长为下级创建分站</div>
        <div class="item" :class="Type == 20 ? 'active' : ''" @click="handleChangeType(20)">分站用户购买商品提成</div>
        <div class="item" :class="Type == 21 ? 'active' : ''" @click="handleChangeType(21)">分站用户购买分站收益</div>
        <div class="item" :class="Type == 22 ? 'active' : ''" @click="handleChangeType(22)">提现</div>
      </div>
      <div class="table-item">
        <el-table :data="recordList" stripe style="width: 100%" height="550">
          <el-table-column prop="Status" label="订单状态" align="center">
            <template #default="scope">
              <span class="red" v-if="scope.row.Type == 20 && scope.row.IsRebates == 0">冻结中</span>
              <span v-else>已发放</span>
            </template>
          </el-table-column>
          <el-table-column prop="Type" label="类型" align="center">
            <template #default="scope">
              <span v-if="scope.row.Type == 1">充值</span>
              <span v-if="scope.row.Type == 2">购买商品</span>
              <span v-if="scope.row.Type == 3">接口扣款</span>
              <span v-if="scope.row.Type == 4">余额转佣金</span>
              <span v-if="scope.row.Type == 5">佣金转余额</span>
              <span v-if="scope.row.Type == 6">系统</span>
              <span v-if="scope.row.Type == 7">退款</span>
              <span v-if="scope.row.Type == 8">站长为下级创建分站</span>
              <span v-if="scope.row.Type == 20">分站用户购买商品提成</span>
              <span v-if="scope.row.Type == 21">分站用户购买分站收益</span>
              <span v-if="scope.row.Type == 22">提现</span>
            </template>
          </el-table-column>
          <el-table-column prop="Money" label="金额" align="center">
            <template #default="scope">
              <span v-if="scope.row.Money > 0" style="color: #1399ff;">+{{ parseFloat(scope.row.Money).toFixed(6) }}</span>
              <span v-else style="color: #ff2442;">{{ parseFloat(scope.row.Money).toFixed(6) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="Content" label="说明" align="center"></el-table-column>
          <el-table-column prop="AddTime" label="时间" align="center">
            <template #default="scope">
              {{ $TOOL.dateFormat(scope.row.AddTime * 1000, 'yyyy-MM-dd hh:mm') }}
            </template>
          </el-table-column>
        </el-table>
        <div v-if="total > PageCount" class="pagination-item">
          <el-pagination background layout="prev, pager, next" :page-size="PageCount" :total="total" @current-change="handleCurrentPageChange"></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import pcPublicHeader from '@/components/pcPublicHeader'

export default {
  name: "BalanceRecord",
  components: {
    pcPublicHeader
  },
  data() {
    return {
      Type: 0,
      PageCount: 10,
      Page: 1,
      total: 0,
      loading: false,
      finish: false,
      recordList: []
    }
  },
  computed: {
    ...mapState(['userInfo', 'websiteInfo'])
  },
  filters: {
    
  },
  mounted() {
    this.handleGetIncomeLogList()
  },
  methods: {
    ...mapActions(['GetUserInfo']),
    handleChangeType(type) {
      this.Type = type
      this.Page = 1
      this.total = 0
      this.finish = false
      this.loading = false
      this.recordList = []
      this.handleGetIncomeLogList()
    },
    handleCurrentPageChange(val) {
      this.page = val
      this.handleGetIncomeLogList()
    },
    async handleGetIncomeLogList() {
      const { PageCount, Page, Type } = this
      const params = {
        PageCount,
        Page
      }
      if (Type) {
        params.Type = Type
      }
      var res = await this.$API.incomeLogList.post(params)
      this.recordList = [
        ...res.info
      ]
      this.total = res.count
      this.Page = Page + 1
      this.finish = res.info.length < PageCount
      this.loading = false
    }
  }
};
</script>

<style lang="less" scoped>
.page-title {
  padding: 30px 0 22px;
  line-height: 48px;
  font-size: 32px;
  font-weight: bold;
  color: #3d3d42;
}
.tab-item {
  width: 100%;
  height: 64px;
  border-radius: 8px 8px 0 0;
  background-color: #fafbff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .item {
    cursor: pointer;
    text-align: center;
    width: 124px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px 8px 0 0;
    margin-right: 30px;
    font-size: 16px;
    color: #3d3d42;
    &.active,
    &:hover {
      font-weight: bold;
      color: #6a5ffe;
      background-color: #fff;
      box-shadow: 4px -4px 12px 0 rgba(0, 0, 0, .06);
    }
  }
}
.table-item {
  padding: 30px;
  background-color: #fff;
  border-radius: 0 0 8px 8px;
  .red {
    color: #f00;
  }
}
</style>
